import styles from "./NucleAppProduct.module.css";
import logo from "../../../assets/Logo.svg";

export default function NucleAppProduct() {
  return (
    <section className={styles.section}>
      <div className={styles.text}>
        <div className={styles.textMedium}> A product from</div>
        <div className={styles.app}>
          <img className={styles.image} src={logo} />
          <span> NucleApp</span>
        </div>{" "}
        <div className={styles.textMedium}> trusted by</div>
      </div>
      <div className={styles.textBold}>1,00,000+ Users</div>
    </section>
  );
}
