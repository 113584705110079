import { getCustomerById } from "../../../services/profile-page-api";
import { CircularProgress } from "@mui/material";
import { BatchCard } from "../../../../beta-home/components/layouts/Batches/Batches";

import "./Batches.css";
import useDataFetcher from "../../../../beta-home/services/helper/useDataFetcher";

export default function Batches() {
  const { data, loading } = useDataFetcher({ getFunction: getCustomerById });

  const batchesList = data?.customer?.batches;

  return (
    <>
      {loading ? (
        <div className="loader-wrapper">
          <CircularProgress variant="soft" thickness={4} />
        </div>
      ) : (
        <div className="dashboard-batches">
          <ul>
            {batchesList &&
              batchesList.map((batch, index) => {
                return (
                  <li key={`batch-${index}`}>
                    <BatchCard isMemberPage batchData={batch} />
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </>
  );
}
