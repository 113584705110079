import styles from "./BatchesPage.module.css";
import Batches from "../../../layouts/Batches/Batches";
import { useRef } from "react";
import AboutUs from "../../../layouts/Footer/AboutUs";
import getLandingPageData from "../../../../services/helper/get_landing_page_data";
import Header from "../../../layouts/Header/Header";

import { ThemeProvider } from "../../../ThemeProvider.jsx";
import MenuModal from "../../../layouts/modals/Menu-Modal/MenuModal";

export default function BatchesPage() {
  const menuModalRef = useRef(null);
  const landingPageData = getLandingPageData(window.pageData);

  const { business, socialLinks, theme, salesPerson, formIds } =
    landingPageData;

  const fontName = (theme) => {
    return theme.toLowerCase().replace(/\s+/g, "");
  };

  const fontClassName = fontName(theme.fontStyle);

  function updateRootVariable(variable, value) {
    document.documentElement.style.setProperty(variable, value);
  }

  if (theme.color) {
    updateRootVariable("--primary-color", theme.color);
  }

  return (
    <ThemeProvider>
      <div className={`${styles.batches} ${styles[fontClassName]} `}>
        <Header
          businessDetails={business}
          onOpen={() => menuModalRef.current.showModal()}
          sales={salesPerson}
          formId={formIds.leadFormId}
          batchesPage
        />
        <MenuModal
          formId={formIds.leadFormId}
          ref={menuModalRef}
          sales={salesPerson}
          batchesPage
        />
        <div className={styles.batchCards}>
          <Batches
            isBatchesPage
            batchFormId={formIds.newMemberFormId}
            businessDetails={business}
          />
        </div>
        <AboutUs
          sales={salesPerson}
          businessDetails={business}
          socialLinks={socialLinks}
        />
      </div>
    </ThemeProvider>
  );
}
