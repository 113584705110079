import { formatTime, useWindowWidth } from "../../../../shared/helper";
import "./UpcomingClasses.css";
import {
  resultedArrayWithDates,
  getUpcomingClasses,
  loading,
} from "./getUpcomingClasses";

import { getCustomerById } from "../../../services/profile-page-api";
import useDataFetcher from "../../../../beta-home/services/helper/useDataFetcher";

import { CircularProgress } from "@mui/material";

export default function UpcomingClasses() {
  const { isMobile } = useWindowWidth();

  const { data: batchData, loading } = useDataFetcher({
    getFunction: getCustomerById,
  });

  function addMinutes(timeStr, minutesToAdd) {
    const [hours, minutes] = timeStr.split(":").map(Number);

    const date = new Date();
    date.setHours(hours, minutes, 0, 0);

    date.setMinutes(date.getMinutes() + minutesToAdd);

    const newHours = String(date.getHours()).padStart(2, "0");
    const newMinutes = String(date.getMinutes()).padStart(2, "0");

    const newTime = `${newHours}:${newMinutes}`;

    return formatTime(newTime);
  }

  const resultedArrayWithDates = getUpcomingClasses(batchData);

  return (
    <>
      {loading ? (
        <div className="loader-wrapper">
          <CircularProgress />
        </div>
      ) : (
        resultedArrayWithDates &&
        resultedArrayWithDates.length !== 0 && (
          <div className="upcoming-classes-card">
            <div className="card-title-bold">Upcoming Classes</div>
            <div className="upcoming-classes-list">
              {resultedArrayWithDates.map((item, index) => (
                <div className="upcoming-classes-list-item" key={index}>
                  {!isMobile ? (
                    <>
                      <div className="date">{item.formattedDate}</div>
                      <div className="title">
                        {item?.general_details?.title}
                      </div>
                      <div className="time">
                        {`${formatTime(item?.schedule?.time)} - ${addMinutes(
                          item?.schedule?.time,
                          item?.schedule?.duration
                        )}`}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="time-and-date">
                        <div className="time">
                          {formatTime(item?.schedule?.time)} -
                          {addMinutes(
                            item?.schedule?.time,
                            item?.schedule?.duration
                          )}
                        </div>
                        <div className="date">{item.formattedDate} </div>
                      </div>
                      <div className="title">
                        {item?.general_details?.title}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        )
      )}
    </>
  );
}
