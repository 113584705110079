import { timeInMillis } from "../../../../shared/helper";

export function getUpcomingClasses(batchData) {
  const upcomingClasses = batchData?.customer?.batches
    ? batchData.customer.batches
    : [];

  const todayInMillis = new Date().getTime();

  function formatDate(millis) {
    const today = new Date();
    const date = new Date(millis);

    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);

    if (today.getTime() === date.getTime()) {
      return "Today";
    }

    const options = {
      weekday: "short",
      day: "2-digit",
      month: "short",
    };

    let formattedDate = date.toLocaleDateString("en-US", options);

    formattedDate = formattedDate.replace(",", "");

    const [weekday, day, month] = formattedDate.split(" ");
    const finalFormattedDate = `${day} ${month}, ${weekday}`;

    return finalFormattedDate;
  }

  const getClassDates = (schedule) => {
    switch (schedule.session_frequency) {
      case "one-time":
        return handleOneTimeFrequency(schedule);
      case "weekly":
        return handleWeeklyFrequency(schedule);
      case "monthly":
        return handleMonthlyFrequency(schedule);
      default:
        return [];
    }
  };

  const handleOneTimeFrequency = (schedule) => {
    const oneTimedate = schedule.date + timeInMillis(schedule.time);

    return oneTimedate;
  };

  const handleWeeklyFrequency = (schedule) => {
    const weeklyActiveDays = schedule.days_active;
    const startDate = schedule.start_date;
    const endDate = schedule.end_date;

    const endDateMillis = endDate
      ? endDate + timeInMillis(schedule.time)
      : null;

    const startTime = timeInMillis(schedule.time);

    const getDayIndex = (day) => {
      const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
      return daysOfWeek.indexOf(day.toUpperCase());
    };

    function findWeeklyActiveDates(activeDays, startingDateMillis) {
      const weeklyActiveDatesArray = [];
      const startingDate = new Date(startingDateMillis);
      const startingDayIndex = startingDate.getDay();

      activeDays.forEach((day) => {
        const targetDayIndex = getDayIndex(day);

        let dayDiff = targetDayIndex - startingDayIndex;

        if (dayDiff < 0) dayDiff += 7;

        let weekDate = new Date(startingDate);
        weekDate.setDate(startingDate.getDate() + dayDiff);

        if (endDateMillis) {
          while (weekDate.getTime() <= endDateMillis) {
            weeklyActiveDatesArray.push(weekDate.getTime() + startTime);
            weekDate.setDate(weekDate.getDate() + 7);
          }
        } else {
          let weekDate = new Date(startingDate + startTime);

          while (weeklyActiveDatesArray.length <= 50) {
            if (weekDate.getTime() >= todayInMillis) {
              weeklyActiveDatesArray.push(weekDate.getTime());
              weekDate.setDate(weekDate.getDate() + 7);
            } else {
              weekDate.setDate(weekDate.getDate() + 7);
            }
          }
        }
      });

      return weeklyActiveDatesArray;
    }

    return findWeeklyActiveDates(weeklyActiveDays, startDate);
  };

  const handleMonthlyFrequency = (schedule) => {
    const startingDate = schedule.start_date;
    const startingMonthlyDate = schedule.month_date;
    const startTime = timeInMillis(schedule.time);

    const endDate = schedule.end_date;

    const endDateMillis = endDate
      ? endDate + timeInMillis(schedule.time)
      : null;

    const generateMonthlyDates = (startingMonthlyDate) => {
      const monthlyActiveDatesArray = [];
      monthlyActiveDatesArray.push(startingDate + startTime);
      let monthDate = new Date(startingMonthlyDate);

      if (endDateMillis) {
        while (monthDate.getTime() <= endDateMillis) {
          monthlyActiveDatesArray.push(monthDate.getTime() + startTime);
          monthDate.setMonth(monthDate.getMonth() + 1);
        }
      } else {
        let monthDate = new Date(startingMonthlyDate + startTime);

        while (monthlyActiveDatesArray.length <= 50) {
          if (monthDate.getTime() >= todayInMillis) {
            monthlyActiveDatesArray.push(monthDate.getTime());
            monthDate.setMonth(monthDate.getMonth() + 1);
          } else {
            monthDate.setMonth(monthDate.getMonth() + 1);
          }
        }
      }

      return monthlyActiveDatesArray;
    };

    return generateMonthlyDates(startingMonthlyDate);
  };

  const array = upcomingClasses ? upcomingClasses : [];

  array.forEach((item) => {
    if (item.schedule) {
      item.schedule.classDate = getClassDates(item.schedule);
    }
  });

  const classDateDiff =
    array &&
    array.length !== 0 &&
    array.flatMap((item) => {
      const dates = Array.isArray(item.schedule.classDate)
        ? item.schedule.classDate
        : [item.schedule.classDate];

      return dates.map((date) => ({
        id: item._id,
        diff: date - todayInMillis,
      }));
    });

  const upcomingClassDate =
    array && array.length !== 0
      ? array.flatMap((item) => {
          const dates = Array.isArray(item.schedule.classDate)
            ? item.schedule.classDate
            : [item.schedule.classDate];
          return dates;
        })
      : [];

  const topThreeUpcomingClassesDate =
    upcomingClassDate && upcomingClassDate.length !== 0
      ? upcomingClassDate
          ?.filter((date) => date - todayInMillis > 0)
          .sort((a, b) => a - b)
          .slice(0, 3)
      : [];

  const formattedClassDate = topThreeUpcomingClassesDate.map((item) =>
    formatDate(item)
  );

  const calculateUpcomingClassTime = () => {
    const closestUpcoming = classDateDiff
      ? classDateDiff
          .filter((item) => item.diff > 0)
          .sort((a, b) => a.diff - b.diff)
      : [];

    const topThreeUpcoming =
      closestUpcoming.length > 3
        ? closestUpcoming.slice(0, 3)
        : closestUpcoming;

    return topThreeUpcoming;
  };

  const calculatedUpcomingClassesList = calculateUpcomingClassTime();

  const resultedArray =
    Array.isArray(calculatedUpcomingClassesList) &&
    calculatedUpcomingClassesList.length > 0
      ? calculatedUpcomingClassesList.map((item) =>
          upcomingClasses.find((obj) => obj._id === item.id)
        )
      : [];

  const resultedArrayWithDates = resultedArray.map((item, index) => ({
    ...item,
    formattedDate: formattedClassDate[index] || "",
  }));

  return resultedArrayWithDates;
}
