import AppDetails from "../app-details/AppDetails";
import QueryInput from "../query-input/QueryInput";
import styles from "./EnquiryModal.module.css";
import { forwardRef, useState } from "react";
import { VscClose } from "react-icons/vsc";
import { useTheme } from "../../../ThemeProvider";
import getLandingPageData from "../../../../services/helper/get_landing_page_data";

const EnquiryModal = forwardRef(({ formId, onClose }, ref) => {
  const landingPageData = getLandingPageData(window.pageData);

  const { templateClassName } = useTheme();
  const { business } = landingPageData;

  const [modalClose, setModalClose] = useState(false);

  return (
    <dialog
      className={`${styles.enquiryModal} ${styles[templateClassName]}`}
      ref={ref}
    >
      {}
      <div className={styles.modal}>
        <div className={styles.closeModal}>
          <button
            className={styles.close}
            onClick={() => {
              setModalClose(true);
              onClose();
            }}
          >
            <VscClose />
          </button>
        </div>
        <div className={styles.header}>
          <div>
            <img src={business.logo} />
          </div>
          <div>
            <h3>{business.name}</h3>
          </div>
        </div>
        <div className={styles.queryModal}>
          <div className={styles.appDetailsSection}>
            <AppDetails />
          </div>
          <div className={styles.queryInputSection}>
            <QueryInput
              formId={formId}
              close={onClose}
              modalClose={modalClose}
              setModalClose={setModalClose}
            />
          </div>
        </div>
      </div>
    </dialog>
  );
});

export default EnquiryModal;
