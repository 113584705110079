import { useEffect, useRef, useState } from "react";
import { MdLocalPhone } from "react-icons/md";
import "./Header.css";
import AccountPopup from "../account-popup/AccountPopup";
import blankProfile from "../../../assets/blank_profile.png";
import { getCustomerById } from "../../../services/profile-page-api";
import useDataFetcher from "../../../../beta-home/services/helper/useDataFetcher";

export default function Header({ businessDetails }, props) {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);

  function togglePopup() {
    setShowPopup(!showPopup);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { data, loading, ErrorSnackBar } = useDataFetcher({
    getFunction: getCustomerById,
  });

  const memberInfo = data?.customer;

  return (
    <div className="dashboard-header">
      <div className="brand-logo">
        <img src={businessDetails.logo} alt="" />
        <span>{businessDetails.name}</span>
      </div>

      <div className="contact-us-details">
        {businessDetails.mobileNumber && (
          <div className="call-at">
            <span>Call at</span>
            <div className="phone-number">
              <MdLocalPhone />
              <span> {businessDetails.mobileNumber}</span>
            </div>
          </div>
        )}
        {memberInfo?.image ? (
          <img
            src={memberInfo?.image}
            alt=""
            className="customer-image"
            onClick={togglePopup}
          />
        ) : (
          <img
            src={blankProfile}
            className="customer-image"
            onClick={togglePopup}
            alt=""
          />
        )}
        {showPopup && (
          <AccountPopup {...props} ref={popupRef} onClose={togglePopup} />
        )}
      </div>
      {ErrorSnackBar()}
    </div>
  );
}
