import styles from "./PreviewImage.module.css";
import whatsapp from "../../../assets/whatsapp.svg";
import { useTheme } from "../../ThemeProvider.jsx";

export default function PreviewImage({ welcomeSectionDetails, sales }) {
  const salesWhatsappNumber = sales.whatsappNumber;
  const handleClick = () => {
    window.open(
      `https://wa.me/${salesWhatsappNumber}`,
      "_blank",
      "noopener, noreferrer"
    );
  };

  const { templateClassName, templateName } = useTheme();

  return (
    <div
      className={`${styles.previewImage} ${styles[templateClassName]}`}
      style={
        !(templateName === "midnightzen" || templateName === "minimalist")
          ? {
              backgroundImage: `url(${welcomeSectionDetails.image})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }
          : {}
      }
    >
      <div className={styles.textOverlay}>
        <div className={styles.text}>
          <span className={styles.title}>{welcomeSectionDetails.title}</span>
          <span className={styles.desc}>{welcomeSectionDetails.aboutUs}</span>
        </div>
      </div>{" "}
      {templateName === "midnightzen" || templateName === "minimalist" ? (
        <div className={styles.image}>
          <img src={welcomeSectionDetails.image} />
        </div>
      ) : (
        ""
      )}
      {salesWhatsappNumber && (
        <div className={styles.whatsapp} onClick={handleClick}>
          <img src={whatsapp} alt="" />
        </div>
      )}
    </div>
  );
}
