import PaymentsModal from "../../payment-modal/PaymentsModal";
import "./InvoiceCard.css";
import { useRef, useEffect, useState } from "react";
import { formatDate } from "../../../../../shared/helper";
import { useNavigate } from "react-router-dom";

export default function InvoiceCard({
  memberInfo,
  invoicesData,
  formId,
  isRecent,
  pendingVerificationInvoiceId,
  handlePaymentSuccess,
  addPaymentInvoiceId,
}) {
  const payNowModal = useRef(null);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const navigate = useNavigate();

  function handlePayNowBtnClick(invoiceId) {
    const selectedInvoice = invoices.find((i) => i._id === invoiceId);

    setSelectedInvoice(selectedInvoice);
  }

  useEffect(() => {
    if (selectedInvoice && payNowModal.current) {
      payNowModal.current.showModal();
    }
  }, [selectedInvoice]);

  function handlePayNowModalClose() {
    payNowModal.current.close();

    setSelectedInvoice(null);
  }

  const invoices = isRecent ? invoicesData.slice(-3) : invoicesData;

  function handlePaymentSuccess() {
    addPaymentInvoiceId(selectedInvoice._id);
  }

  function handleSubmissionConfirmationPopupClose() {
    setSelectedInvoice(null);
  }

  function navigateInvoicePage(id) {
    navigate(`/invoice?${new URLSearchParams({ id })}`);
  }

  return (
    <>
      {invoices &&
        memberInfo &&
        invoices.map((invoice, index) => (
          <div
            key={index}
            className={`dashboard-invoice-card ${isRecent ? "recent" : ""}`}
          >
            <div className="name-inv-no-row">
              <div className="name">{memberInfo?.name}</div>
              <div className="inv-no">Inv. No.</div>
            </div>
            <div className="date-invoice-no-row">
              <div className="date">{formatDate(invoice.date)}</div>
              <div className="invoice-no">{invoice.invoice_number}</div>
            </div>
            <div className="payment-info-row">
              <div className="amount-mop-row">
                <div className="amount">₹{invoice.total_amount}</div>
                {invoice.mode_of_payment && (
                  <div className="mop">{invoice.mode_of_payment}</div>
                )}
              </div>
              {pendingVerificationInvoiceId.includes(invoice._id) &&
              invoice.payment_status !== "PAID" ? (
                <button className={`payment-button pending`}>
                  Pending Verification
                </button>
              ) : invoice.payment_status === "PAID" ? (
                <button
                  onClick={() => navigateInvoicePage(invoice._id)}
                  className={`payment-button ${invoice.payment_status}`}
                >
                  Download Receipt
                </button>
              ) : (
                <button
                  className={`payment-button ${invoice.payment_status}`}
                  onClick={() => handlePayNowBtnClick(invoice._id)}
                >
                  Pay now
                </button>
              )}
              {selectedInvoice && formId && (
                <PaymentsModal
                  ref={payNowModal}
                  formId={formId}
                  invoice={selectedInvoice}
                  memberInfo={memberInfo}
                  handleClose={handlePayNowModalClose}
                  onPaymentSuccess={handlePaymentSuccess}
                  onSubmissionConfirmationPopupClose={
                    handleSubmissionConfirmationPopupClose
                  }
                />
              )}
            </div>
          </div>
        ))}
    </>
  );
}
