import { useState, useEffect } from "react";

export function useFetchPaymentStatus({
  formData,
  responses: allResponses,
  memberInfo,
}) {
  const [invoiceIdArray, setInvoiceIdArray] = useState([]);

  useEffect(() => {
    const questionsArray = formData?.questions;

    if (!questionsArray || !allResponses || !memberInfo) {
      setInvoiceIdArray([]);
      return;
    }

    const memberDbIdQuestionId = questionsArray?.find(
      (question) => question.title === "Member DB ID"
    )?._id;

    const invoiceQuestionId = questionsArray?.find(
      (question) => question.title === "Invoice DB ID"
    )?._id;

    const memberDbId = memberInfo?._id;

    const responseOfTheMember = allResponses?.filter((response) =>
      response?.responses.some(
        (answer) =>
          answer?.question_id === memberDbIdQuestionId &&
          answer?.text === memberDbId
      )
    );

    console.log(responseOfTheMember);

    const invoiceIds =
      responseOfTheMember
        ?.flatMap((response) => response.responses)
        ?.filter((response) => response.question_id === invoiceQuestionId)
        ?.map((result) => result.text) || [];

    setInvoiceIdArray(invoiceIds);
  }, [formData, allResponses, memberInfo]);

  function addPaymentInvoiceId(invoiceId) {
    setInvoiceIdArray((prevId) => [...prevId, invoiceId]);
  }

  return { invoiceIdArray, addPaymentInvoiceId };
}
