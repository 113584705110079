import axios from "axios";
import { BASE_URL } from "../../../shared/constants";
import { getCustomerToken } from "../../../shared/helper";

export async function getAllResponses(onSuccess, onFailure, formId) {
  try {
    const customerToken = getCustomerToken();

    const response = await axios.post(
      `${BASE_URL}/responses/form/${formId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${customerToken}`,
        },
      }
    );

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );
    if (onFailure) {
      const errorMessage = error.response ? error.response.data : error.message;
      onFailure(errorMessage);
    }
  }
}
