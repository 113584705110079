import { forwardRef } from "react";
import { MdClose } from "react-icons/md";
import styles from "./MenuModal.module.css";

const MenuModal = forwardRef(function MenuModal(
  { pricingScroll, featuresScroll },
  ref
) {
  return (
    <dialog ref={ref} className={styles.menuModal}>
      <div onClick={() => ref.current.close()} className={styles.close}>
        <button className={styles.closeBtn}>
          <MdClose />
        </button>
      </div>
      <div className={styles.modalContainer}>
        <ul className={styles.menuItems}>
          <li onClick={() => ref.current.close()}>Home</li>
          <li onClick={featuresScroll}>Features</li>
          <li onClick={pricingScroll}>Pricinig</li>
          <li>Contact Us</li>
          <li>
            <button className={styles.bgBlack}>Sign in</button>
          </li>
          <li>
            <button className={styles.bgBlue}>Get a Demo</button>
          </li>
        </ul>
      </div>
    </dialog>
  );
});

export default MenuModal;
