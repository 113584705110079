import image from "../../../assets/get-started.png";
// import { TiSocialYoutube } from "react-icons/ti";
import { FaYoutube } from "react-icons/fa";
import youtube from "../../../assets/logos_youtube-icon.svg";
import styles from "./GetStarted.module.css";

export default function GetStarted() {
  return (
    <div className={styles.getStarted}>
      <div className={styles.getStartedContent}>
        <div className={styles.textLight}>All-in-one Yoga Studio Solution</div>

        <div className={styles.textBold}>
          Your yoga studio business made Simple, Effective and Stress-free!
        </div>

        <div className={styles.getStartedBtn}>
          {" "}
          <button className={styles.bgBlue}>Start for Free</button>
          <button className={styles.bgWhite}>
            {" "}
            <span className={styles.youtube}>
              {/* <img src={youtube} /> */}
              <FaYoutube />
            </span>
            <div> Watch Video</div>
          </button>
        </div>
      </div>

      <img src={image} alt="" />
    </div>
  );
}
