import { useState, useRef } from "react";
import { timeInMillis, formatTime } from "../../../shared/helper";
import useDataFetcher from "./useDataFetcher";
import { getFormByFormId } from "../network/get-form-by-formId";
import { uploadFileToAWS, awsRoles } from "../../../shared/aws";

export default function useFormHandler({ submissionPopupIdentifiers, formId }) {
  const submissionConfirmationRef = useRef();

  const formRef = useRef();

  const [responses, setResponses] = useState([]);

  const [fileUploadResponse, setFileUploadResponse] = useState({
    question_id: "",
    type: "FILE_UPLOAD",
    file_url: "",
    text: "",
  });

  const { data: formDataState, loading } = useDataFetcher({
    getFunction: getFormByFormId,
    params: formId,
  });

  const submissionPopupDatatInitialState = submissionPopupIdentifiers.reduce(
    (acc, field) => {
      acc[field] = "";
      return acc;
    },
    {}
  );

  const [submissionConfirmationData, setSubmissionConfirmationData] = useState(
    submissionPopupDatatInitialState
  );

  const [fileMissing, setFileMissing] = useState(false);

  const formData = formDataState && formDataState;

  const findQuestionId = (questionType, questionTitle, maxLength) => {
    const question =
      formData &&
      formData.questions.find(
        (question) =>
          (questionTitle ? question.title === questionTitle : true) &&
          (questionType ? question.type === questionType : true) &&
          (maxLength ? question.max_value <= maxLength : true)
      );

    const questionId = question ? question._id : null;

    return questionId;
  };

  // Handle File Upload Changes

  const handleFileUploadInputChange = async (e) => {
    if (e.target.files.length > 0) {
      setFileMissing(false);
    }

    try {
      const url = await uploadFileToAWS(e.target.files[0], awsRoles.MEMBER);
      setFileUploadResponse((prevValue) => {
        return {
          ...prevValue,
          question_id: e.target.name,
          file_url: url,
        };
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleRefNumberChange = (e) => {
    setFileUploadResponse((prevValue) => ({
      ...prevValue,
      question_id: e.target.name,
      text: e.target.value,
    }));
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;

    const nameQuestionId = findQuestionId("TEXT", "Name", "");

    const timeQuestionId = findQuestionId("TIME", "", "");

    const dateQuestionId = findQuestionId("DATE", "", "");

    const choiceQuestionId = findQuestionId("CHOICE", "", "");

    const enquiryFormNameQuestionId = findQuestionId("TEXT", "", 100);

    // Submission Confirmation Data

    if (
      type === "text" &&
      name === nameQuestionId &&
      submissionPopupIdentifiers.includes("name")
    ) {
      setSubmissionConfirmationData((prevResponses) => ({
        ...prevResponses,
        name: value,
      }));
    }

    if (
      name === enquiryFormNameQuestionId &&
      submissionPopupIdentifiers.includes("name")
    ) {
      if (type === "text") {
        setSubmissionConfirmationData((prevResponses) => ({
          ...prevResponses,
          name: value,
        }));
      }
    }

    if (type === "tel" && submissionPopupIdentifiers.includes("mobileNumber")) {
      setSubmissionConfirmationData((prevResponses) => ({
        ...prevResponses,
        mobileNumber: value,
      }));
    }

    if (type === "email" && submissionPopupIdentifiers.includes("email")) {
      setSubmissionConfirmationData((prevResponses) => ({
        ...prevResponses,
        email: value,
      }));
    }

    if (type === "date" && submissionPopupIdentifiers.includes("date")) {
      setSubmissionConfirmationData((prevResponses) => ({
        ...prevResponses,
        date: value,
      }));
    }

    if (type === "time" && submissionPopupIdentifiers.includes("time")) {
      setSubmissionConfirmationData((prevResponses) => ({
        ...prevResponses,
        time: formatTime(value),
      }));
    }

    setResponses((prevResponses) => {
      const existingIndex = prevResponses.findIndex(
        (res) => res.question_id === name
      );

      //   Formatting Responses

      const dateString =
        dateQuestionId && name === dateQuestionId && value.valueOf();

      const timeString = timeQuestionId && name === timeQuestionId && value;
      const timeMillis = timeString && timeInMillis(timeString);

      const date = dateString && new Date(dateString);
      const dateMillis = date && date.getTime();

      if (existingIndex !== -1) {
        const updatedResponses = [...prevResponses];
        updatedResponses[existingIndex] = {
          question_id: name,
          type: name === choiceQuestionId ? "CHOICE" : "TEXT",
          ...(name !== choiceQuestionId && {
            text:
              type === "date"
                ? dateMillis
                : type === "time"
                ? timeMillis
                : value,
          }),
          ...(name === choiceQuestionId && {
            choice: value,
          }),
        };
        return updatedResponses;
      } else {
        return [
          ...prevResponses,
          {
            question_id: name,
            type: name === choiceQuestionId ? "CHOICE" : "TEXT",
            ...(name !== choiceQuestionId && {
              text:
                type === "date"
                  ? dateMillis
                  : type === "time"
                  ? timeMillis
                  : value,
            }),
            ...(name === choiceQuestionId && {
              choice: value,
            }),
          },
        ];
      }
    });
  };

  return {
    formRef,
    formData,
    submissionConfirmationRef,
    handleChange,
    submissionConfirmationData,
    setSubmissionConfirmationData,
    responses,
    setResponses,
    fileUploadResponse,
    setFileUploadResponse,
    fileMissing,
    setFileMissing,
    handleFileUploadInputChange,
    handleRefNumberChange,
  };
}
