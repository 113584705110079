import landingPage from "../../../assets/features/landing-page.png";
import batches from "../../../assets/features/batches.png";
import serviceRequest from "../../../assets/features/service-request.png";
import receiptsInvoices from "../../../assets/features/receipts.png";
import customers from "../../../assets/features/customers.png";
import contactForm from "../../../assets/features/contact-form.png";

import styles from "./Features.module.css";
import { forwardRef } from "react";

const Features = forwardRef(function Features({ props }, ref) {
  return (
    <div ref={ref} className={styles.features}>
      <div className={styles.title}>Our Features</div>
      <div className={styles.desc}>
        Manage your business with ease using exclusive features
      </div>
      <div className={styles.featuresList}>
        <Feature
          className={styles.landingPage}
          title="Landing Page"
          desc="Our Yoga App’s Landing Page feature provides a stunning,
            user-friendly introduction to your studio. Showcase your Batches,
            services, Testimonials and more. The landing page is optimized for a
            seamless user experience, helping you attract and convert potential
            clients effectively."
          image={landingPage}
        />
        <Feature
          className={styles.batches}
          title="Batches"
          desc="Our Yoga App’s Batches feature for easy class organization. Create and manage batches, set flexible schedules, track enrollments, and etc.,. Customize each batch and gain performance insights to enhance your yoga studio’s efficiency."
          image={batches}
        />

        <Feature
          className={styles.serviceRequest}
          title="Service Request"
          desc="Our Yoga App’s Service Request feature allows you to easily request private sessions, or custom training plans. Submit your preferences and schedule through the app, and we’ll match you with the perfect instructor or service. Enhance your yoga journey with tailored solutions!"
          image={serviceRequest}
        />

        <Feature
          className={styles.receipts}
          title="Receipts & Invoices"
          desc="Our Yoga App includes a Receipts & Invoices feature to simplify your financial transactions. Easily generate and send receipts and invoices for classes, workshops, and other services directly through the app. Keep track of all your payments and billing history in one place."
          image={receiptsInvoices}
        />

        <Feature
          className={styles.customers}
          title="Customer Management"
          desc="Our Yoga App’s Customer Management feature lets you track Customer’s profiles, attendance, and preferences.Streamline your studio operations and enhance Customer experience effortlessly!"
          image={customers}
        />

        <Feature
          className={styles.contactForm}
          title="Contact Form"
          desc="Our Yoga App’s Contact Form lets users easily request and schedule calls. Streamline your communication and connect with students effortlessly for consultations, private sessions, or support."
          image={contactForm}
        />
      </div>
    </div>
  );
});

function Feature({ className, title, desc, image }) {
  return (
    <>
      <div className={` ${className} ${styles.feature}`}>
        <div className={styles.content}>
          <div className={styles.featureTitle}>{title}</div>
          <div className={styles.featureDesc}>{desc}</div>
        </div>

        <img src={image} alt="" />
      </div>

      <div className={styles.featureMobile}>
        <img src={image} alt="" />
        <div className={styles.featureTitle}>{title}</div>
        <div className={styles.featureDesc}>{desc}</div>
        <div className={styles.line}></div>
      </div>
    </>
  );
}

export default Features;
