import image1 from '../assets/selling_points/1.svg';
import image2 from '../assets/selling_points/2.svg';
import image3 from '../assets/selling_points/3.svg';

export const sellingPoints = [
    {
        title: 'Holistic Approach',
        description: '"Our studio embraces a holistic approach to yoga, integrating physical postures, breathwork, meditation, and mindfulness practices to nurture body, mind, and spirit."',
        url: image1
    },
    {
        title: 'Experienced Instructors',
        description: '"Our team consists of highly skilled and compassionate instructors who are dedicated to supporting students of all levels on their yoga journey, offering personalized guidance and encouragement."',
        url: image2
    },
    {
        title: 'Welcoming Community',
        description: '"We foster a warm and inclusive community where everyone is welcome, creating a supportive environment for students to connect, grow, and thrive together."',
        url: image3
    },
];