import whatsapp from "../../../assets/social/mobile/whatsapp.svg";
import facebook from "../../../assets/social/mobile/facebook.svg";
import instagram from "../../../assets/social/mobile/instagram.svg";
import gmail from "../../../assets/social/mobile/gmail.svg";
import twitter from "../../../assets/social/mobile/twitter.svg";
import share from "../../../assets/social/mobile/share.svg";

import "./InviteFriendsCard.css";

export default function InviteFriendsCard({
  socialLinks,
  businessDetails,
  sales,
  subdomain,
}) {
  return (
    <div className="invite-friends-card">
      <div className="card-title-bold">Invite Friends</div>
      <div className="social-icons-row">
        {sales.whatsappNumber && (
          <SocialIcon
            platform={whatsapp}
            socialLink={`https://wa.me/${sales.whatsappNumber}`}
          />
        )}
        {socialLinks.facebook && (
          <SocialIcon platform={facebook} socialLink={socialLinks.facebook} />
        )}
        {socialLinks.instagram && (
          <SocialIcon platform={instagram} socialLink={socialLinks.instagram} />
        )}
        {businessDetails.email && (
          <SocialIcon
            platform={gmail}
            socialLink={`mailto:${businessDetails.email}`}
          />
        )}
        {socialLinks.twitter && (
          <SocialIcon platform={twitter} socialLink={socialLinks.twitter} />
        )}
        {subdomain && <SocialIcon platform={share} subdomain={subdomain} />}
      </div>
    </div>
  );
}

function SocialIcon({ platform, socialLink, subdomain }) {
  const handleClick = () => {
    if (socialLink) {
      openLink(socialLink);
    } else if (subdomain) {
      shareLink(subdomain);
    }
  };

  function openLink(url) {
    if (url) {
      window.open(url, "_blank", "noopener, noreferrer");
    }
  }

  function shareLink(subdomain) {
    navigator.share({
      url: subdomain,
    });
  }

  return (
    <div className="social-icon" onClick={handleClick}>
      <img src={platform} alt="" />
    </div>
  );
}
