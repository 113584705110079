import { useRef } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Button } from "../../../../shared/components/utils/utils";

import logo from "../../../assets/startappify-logo.svg";
import MenuModal from "../menu/MenuModal";
import "./Header.css";

export default function Header({ onOpen, featuresScroll, pricingScroll }) {
  //todo: if window width is greater than 750px close modal

  return (
    <>
      <MenuModal />
      <div className="header" id="header">
        <div className="brand-logo">
          <img src={logo} alt="" />
          <span>Yogawav</span>
        </div>

        <span className="menu-icon" onClick={onOpen}>
          <GiHamburgerMenu />
        </span>

        <div className="menu">
          <ul className="menuItems">
            <li onClick={featuresScroll} className="menuItem">
              Features
            </li>
            <li onClick={pricingScroll} className="menuItem">
              Pricing
            </li>
            <li className="menuItem">Contact Us</li>
            <button className="bgBlue">Get a Demo</button>
            <button className="bgWhite">Sign in</button>
          </ul>
        </div>
      </div>
    </>
  );
}
