import { formatAmount } from "../../../helper";
import "./cost-summary.css";

export default function CostSummary({ invoiceData }) {
  const totalGST =
    invoiceData?.total_cgst_amount +
    invoiceData?.total_sgst_amount +
    invoiceData?.total_igst_amount;
  return (
    <div className="cost-summary">
      <div className="titles">
        <span>Sub Total</span>
        <span>Discount</span>
        <span>GST</span>
        <span>Bill Amount (₹)</span>
      </div>

      <div className="amount">
        <span>{formatAmount(invoiceData?.subtotal_amount)}</span>
        <span>(-) {formatAmount(invoiceData?.discount_amount)}</span>
        <span>{formatAmount(totalGST)}</span>
        <span className="bill-amount">
          {formatAmount(invoiceData?.total_amount)}
        </span>
      </div>
    </div>
  );
}
