import { Snackbar, Alert } from "@mui/material";
import { useEffect, useState } from "react";

export default function useDataFetcher({ getFunction, params }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    getFunction(
      (response) => {
        setData(response);
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setError(true);
        setLoading(false);
      },
      params ? params : ""
    );
  }, [params]);

  function ErrorSnackBar() {
    return (
      <Snackbar
        open={error}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => setError(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Error fetching data! Please try again.
        </Alert>
      </Snackbar>
    );
  }

  return { data, loading, error, ErrorSnackBar };
}
