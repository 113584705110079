import { useState } from "react";
import { postFormResponses } from "../network/post-form-response-api";

export default function useSubmitResponse({
  formData,
  formResponses,
  formRef,
  fileInputRef,
  invoice,
  batchData,
  memberInfo,
  handleModalClose,
  setResponses,
  fileUploadResponse,
  setFileUploadResponse,
  setFileMissing,
  submissionConfirmationRef,
  onPaymentSuccess,
}) {
  const [submissionError, setSubmissionError] = useState();

  const fileUploadQuestion = formData?.questions?.find(
    (question) => question.type === "FILE_UPLOAD"
  );

  function responseForAllQuestions() {
    const newResponses = [];

    if (fileUploadQuestion) {
      if (fileUploadResponse.question_id) {
        newResponses.push(fileUploadResponse);
      } else {
        newResponses.push({
          question_id: fileUploadQuestion?._id,
          type: "FILE_UPLOAD",
          text: fileUploadResponse.text,
          file_url: fileUploadResponse.file_url,
        });
      }
    }

    formData?.questions?.forEach((question) => {
      if (question.title === "Invoice DB ID" && invoice) {
        newResponses.push({
          question_id: question._id,
          type: "TEXT",
          text: invoice?._id,
        });
      } else if (question.title === "Invoice ID") {
        newResponses.push({
          question_id: question?._id,
          type: "TEXT",
          text: invoice?.invoice_number,
        });
      } else if (question.title === "Batch" && batchData) {
        newResponses.push({
          question_id: question?._id,
          type: "TEXT",
          text: batchData?._id,
        });
      } else if (question.title === "Member Email ID" && memberInfo) {
        newResponses.push({
          question_id: question._id,
          type: "TEXT",
          text: memberInfo.email,
        });
      } else if (question.title === "Member DB ID" && invoice) {
        newResponses.push({
          question_id: question._id,
          type: "TEXT",
          text: invoice?.customer_db_id,
        });
      } else if (
        question.type === "CHOICE" &&
        formData?.type === "MANUAL_PAYMENT_FORM"
      ) {
        newResponses.push({
          question_id: question._id,
          type: "CHOICE",
          choice: question.choices[1]._id,
        });
      } else if (question.type === "CHOICE" && question.visibility === false) {
        newResponses.push({
          question_id: question._id,
          type: "CHOICE",
          choice: question.choices[0]._id,
        });
      } else if (question.type !== "CHOICE" && question.visibility === false) {
        newResponses.push({
          question_id: question._id,
          type: "TEXT",
          text: "",
        });
      }
    });

    const combinedResponses = [...formResponses, ...newResponses];

    const nonRequiredQuestionIds = formData?.questions?.filter(
      (q) => q.is_required === false
    );

    const missingResponses = nonRequiredQuestionIds?.filter(
      (q) =>
        !combinedResponses.some((response) => response.question_id === q._id)
    );

    missingResponses?.forEach((q) => {
      if (q.type !== "CHOICE") {
        combinedResponses.push({
          question_id: q._id,
          type: "TEXT",
          text: "",
        });
      } else {
        combinedResponses.push({
          question_id: q._id,
          type: "CHOICE",
          choice: q.choices[0]._id,
        });
      }
    });

    return combinedResponses;
  }

  const clearResponses = () => {
    const inputs = formRef.current.querySelectorAll("input, textarea, select");

    inputs.forEach((input) => {
      input.value = "";
    });

    setResponses && setResponses([]);

    setFileUploadResponse &&
      setFileUploadResponse({
        question_id: "",
        type: "FILE_UPLOAD",
        file_url: "",
        text: "",
      });

    if (fileInputRef) {
      fileInputRef.current.value = null;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formResponsesForAllQuestions = responseForAllQuestions();
    console.log("The form responses are", formResponsesForAllQuestions);

    if (fileUploadQuestion) {
      const fileUrl = formResponsesForAllQuestions?.find(
        (response) => response.question_id === fileUploadQuestion._id
      ).file_url;

      console.log("The form responses are", formResponsesForAllQuestions);

      console.log("The file Url is:", fileUrl);

      if (fileUploadQuestion?.is_required === true && !fileUrl) {
        setFileMissing(true);
        return;
      }
    }

    postFormResponses(
      formData?._id,
      formResponsesForAllQuestions,

      (successData) => {
        handleModalClose();

        submissionConfirmationRef &&
          submissionConfirmationRef.current.showModal();

        onPaymentSuccess && onPaymentSuccess();

        console.log(successData);
      },

      (errorMessage) => {
        setSubmissionError(true);
        console.error("Form submission failed:", errorMessage);
      }
    );
  };

  return {
    handleSubmit,
    clearResponses,
    submissionError,
    setSubmissionError,
  };
}
