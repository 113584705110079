import styles from "./GoogleLoginPage.module.css";
import yogaImage from "../../../assets/signInPopup.png";
import googleLogo from "../../../assets/googleLogo.svg";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { Alert } from "@mui/material";
import { useState } from "react";

export default function GoogleLoginPage(props) {
  const [error, setError] = useState(false);

  const onGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );
      if (userInfo) {
        setError(false);
        props.onSuccess(userInfo.data);
      } else {
        setError(true);
      }
    },
    onError: (errorResponse) => {
      console.log(errorResponse);
      setError(true);
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.signIn}>
        <div className={styles.header}>
          <img src={props.businessLogo} alt={"Logo"} />
          <span className={styles.title}>{props.businessName}</span>
        </div>
        <div className={styles.image}>
          <img src={yogaImage} alt={"Yoga"} />
        </div>
        <div className={styles.description}>
          Please sign in using the Google button below
        </div>
        <div className={styles.button} onClick={() => onGoogleLogin()}>
          <img src={googleLogo} alt={"Google Logo"} />
          <div>Sign in with Google</div>
        </div>
        {props.errorResponseStatus === 400 ? (
          <div className={styles.errorText}>
            You are not a registered user of this portal.
            <br />
            Please verify your user ID or contact the admin{" "}
          </div>
        ) : props.errorResponseStatus || error ? (
          <div className={styles.errorText}>
            Something went wrong. Please try again.
          </div>
        ) : (
          ""
        )}
        {error && (
          <Alert severity="error" onClose={() => setError(false)}>
            Something went wrong. Please try again.
          </Alert>
        )}
      </div>
    </div>
  );
}
