import CostSummary from "../../layouts/cost-summary/cost-summary";
import InvoiceTable from "../../layouts/invoice-table/invoice-table";
import logo from "../../../assets/images/logo.svg";

import { millisToDate } from "../../../helper";
import "./invoice.css";
import { useEffect, useRef, useState } from "react";
import { getInvoiceByID } from "../../../../member-dashboard/services/profile-page-api";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { formatDateTime } from "../../../../shared/helper";
import { useSearchParams } from "react-router-dom";
import useDataFetcher from "../../../../beta-home/services/helper/useDataFetcher";
import { getPaymentInfo } from "../../../../beta-home/services/network/get-payment-info";
import { getCustomerById } from "../../../../member-dashboard/services/profile-page-api";
import getLandingPageData from "../../../../beta-home/services/helper/get_landing_page_data";

export default function Invoice() {
  const invoiceComponentRef = useRef();
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  const [searchParams] = useSearchParams();
  const invoiceId = searchParams.get("id");

  const landingPageData = getLandingPageData(window.pageData);

  const { business } = landingPageData;

  const {
    data,
    loading: isLoading,
    error: invoiceError,
  } = useDataFetcher({
    getFunction: getInvoiceByID,
    params: invoiceId,
  });

  const invoiceData = data?.invoice;

  console.log(invoiceData);

  const {
    data: paymentData,
    loading: loadingPaymentInfo,
    error: paymentError,
    ErrorSnackBar,
  } = useDataFetcher({
    getFunction: getPaymentInfo,
  });

  const paymentInfo = paymentData?.payment_info;

  const {
    data: memberData,
    loading: loadingMemberInfo,
    error,
  } = useDataFetcher({
    getFunction: getCustomerById,
  });

  const memberInfo = memberData?.customer;

  const handleDownloadPDF = () => {
    setIsGeneratingPDF(true);
    html2canvas(invoiceComponentRef.current, { scale: 1 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 0.75);
      const pdf = new jsPDF();
      const imgWidth = 192; // Adjust as necessary
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, "JPEG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("invoice_" + invoiceData._id + ".pdf");
      setIsGeneratingPDF(false);
    });
  };

  return (
    <>
      <main className="invoice-wrapper">
        {isLoading || loadingMemberInfo || loadingPaymentInfo ? (
          <div className="loading">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <div className="download-invoice">
              <button
                disabled={isGeneratingPDF}
                className="download-button"
                onClick={handleDownloadPDF}
              >
                Download
              </button>
            </div>
            <div className="invoice-container" ref={invoiceComponentRef}>
              <div className="block-line"></div>

              <div className="invoice-content">
                <div className="brand-logo">
                  <img src={business.logo} alt="" />
                  <span>{business.name}</span>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="invoice-number">
                    INVOICE {invoiceData?.invoice_number}
                  </div>
                </div>
                <div className="basic-info">
                  {memberInfo?.gst && (
                    <div className="gst">
                      GST: <span className="bold">{memberInfo?.gst}</span>
                    </div>
                  )}

                  <div className="date">
                    DATE:{" "}
                    <span className="bold">
                      {formatDateTime(invoiceData?.date)}
                    </span>
                  </div>
                </div>
                <div className="invoice-address-div">
                  <div className="from">
                    <div className="from-to">From</div>
                    <div className="name">{business.name}</div>
                    <div className="address">{business.address}</div>
                    <div className="mobile">{business.mobileNumber}</div>
                    <div className="email">{business.email}</div>
                  </div>

                  <div className="to">
                    <div className="from-to">To</div>
                    <div className="name">{memberInfo?.name}</div>
                    <div className="address">{memberInfo?.address}</div>
                    <div className="mobile">{memberInfo?.phone}</div>
                    <div className="email">{memberInfo?.email}</div>
                  </div>
                </div>
                <div className="payment-reference">
                  Receipt or Payment reference:{" "}
                  <span>{invoiceData?.receipt_payment_reference ?? "-"}</span>
                </div>
                <InvoiceTable invoiceData={invoiceData} />
                <CostSummary invoiceData={invoiceData} />
                {paymentInfo?.signature_url && (
                  <div className="signature">
                    <span>With Regards,</span>
                    <img src={paymentInfo.signature_url} alt="Signature" />
                  </div>
                )}
              </div>

              <div className="invoice-footer">
                <div className="thank-you">{paymentInfo?.footer_message}</div>

                <div className="powered-by">Powered by YogaWav</div>
              </div>
            </div>
          </>
        )}
        {(error || invoiceError || paymentError) && ErrorSnackBar()}
      </main>
    </>
  );
}
