import styles from "./WhyYogawav.module.css";
import { sellingPoints } from "../../../../beta-home/data/selling-points-data";

export default function WhyYogawav() {
  return (
    <section className={styles.section}>
      <div className={styles.title}>Why Yogawav</div>
      <div className={styles.desc}>Always focusing on your business growth</div>
      <div className={styles.cardGrid}>
        {sellingPoints.map((point) => (
          <WhyYogaWavCard list={point} />
        ))}
        {sellingPoints.map((point) => (
          <WhyYogaWavCard list={point} />
        ))}
      </div>
    </section>
  );
}

export function WhyYogaWavCard({ list }) {
  return (
    <div className={styles.card}>
      <div className={styles.imgRounded}>
        <img className={styles.image} src={list.url} />
      </div>
      <div className={styles.whyTitle}>{list.title}</div>
      <div className={styles.whyDesc}>{list.description}</div>
    </div>
  );
}
