import { getMemberDataLocal, setMemberDataLocal } from "../../../shared/helper";
import GoogleLoginPage from "../../../shared/components/pages/google-login-page/GoogleLoginPage";
import { loginMember } from "../../services/profile-page-api";
import { useEffect, useState } from "react";

export default function MemberGoogleLogin() {
  const [errorResponseStatus, setErrorResponseStatus] = useState();

  const queryParams = new URLSearchParams(window.location.search);

  const subdomain = queryParams.get("sd");

  const businessLogo = localStorage.getItem("imageurl");
  const businessName = localStorage.getItem("businessName");

  async function handleSuccessGoogleLogin(googleUserInfo) {
    console.log(googleUserInfo);

    await loginMember(
      googleUserInfo.email,
      setErrorResponseStatus,
      (serverMemberData) => {
        const encodedAuthData = setMemberDataLocal(
          JSON.stringify(serverMemberData)
        );

        console.log(getMemberDataLocal());
        console.log(googleUserInfo.email);
        console.log(encodedAuthData);

        window.location.replace(
          `${window.location.protocol}//${subdomain}.${window.location.host}/member?auth=${encodedAuthData}`
        );
      },
      () => {
        console.log("Login failed");
      }
    );
  }

  return (
    <GoogleLoginPage
      onSuccess={handleSuccessGoogleLogin}
      errorResponseStatus={errorResponseStatus}
      businessLogo={businessLogo}
      businessName={businessName}
    />
  );
}
