import instagramIcon from "../../../assets/social/instagram.svg";
import facebookIcon from "../../../assets/social/facebook.svg";
import twitterIcon from "../../../assets/social/twitter.svg";
import linkedinIcon from "../../../assets/social/linkedin.svg";
import youtubeIcon from "../../../assets/social/youtube.svg";

import styles from "./AboutUs.module.css";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLogoWhatsapp, IoMdMail } from "react-icons/io";
import { useTheme } from "../../ThemeProvider";
import { formatAddress } from "../../../../shared/helper";

export default function AboutUs({ businessDetails, socialLinks, sales }) {
  function openLink(url) {
    if (url) {
      window.open(url, "_blank", "noopener, noreferrer");
    }
  }

  const theme = useTheme();
  const templateClassName = theme?.templateClassName || "";

  return (
    <footer
      className={`${styles[templateClassName]}`}
      style={{ marginTop: "0" }}
    >
      <div className={styles["about-us"]}>
        <div className={styles.contact}>
          <div className={styles["app-logo-title"]}>
            <img src={businessDetails.logo} alt="App Logo" />
            <span>{businessDetails.name}</span>
          </div>
          <AboutUsSales sales={sales} />
        </div>
        <div className={styles["address-div"]}>
          <span className={styles["address-title"]}>Address</span>
          <span className={styles.address}>
            {formatAddress(businessDetails.address)}
          </span>
          {businessDetails.email && (
            <div className={styles.emailAddress}>
              <span className={styles.emailDetails}>
                {businessDetails.email}
              </span>
            </div>
          )}
        </div>
        <div className={styles["contact-sales-mobile"]}>
          <AboutUsSales sales={sales} />
        </div>
        <div className={styles.social}>
          {socialLinks.twitter ||
          socialLinks.facebook ||
          socialLinks.instagram ||
          socialLinks.linkedin ||
          socialLinks.youtube ? (
            <span>Know us better</span>
          ) : (
            ""
          )}
          <div className={styles["social-icons"]}>
            {socialLinks.instagram && (
              <img
                src={instagramIcon}
                alt="Instagram"
                onClick={() => openLink(socialLinks.instagram)}
              />
            )}
            {socialLinks.facebook && (
              <img
                src={facebookIcon}
                alt="Facebook"
                onClick={() => openLink(socialLinks.facebook)}
              />
            )}
            {socialLinks.twitter && (
              <img
                src={twitterIcon}
                alt="Twitter"
                onClick={() => openLink(socialLinks.twitter)}
              />
            )}
            {socialLinks.linkedin && (
              <img
                src={linkedinIcon}
                alt="LinkedIn"
                onClick={() => openLink(socialLinks.linkedin)}
              />
            )}
            {socialLinks.youtube && (
              <img
                src={youtubeIcon}
                alt="YouTube"
                onClick={() => openLink(socialLinks.youtube)}
              />
            )}
          </div>
        </div>
      </div>
      <p className={styles.poweredBy}>Powered by NucleApp</p>
    </footer>
  );
}

function AboutUsSales({ sales }) {
  return (
    <div className={styles["contact-sales"]}>
      {sales.mobileNumber || sales.email || sales.whatsappNumber ? (
        <div className={styles.title}>Contact Sales</div>
      ) : (
        ""
      )}
      <div className={styles["contact-sales-details"]}>
        {sales.mobileNumber && (
          <div className={styles.salesDetails}>
            <FaPhoneAlt />{" "}
            <span className={styles.details}>{sales.mobileNumber}</span>
          </div>
        )}

        {sales.email && (
          <div className={styles.salesDetails}>
            <IoMdMail /> <span className={styles.details}>{sales.email}</span>
          </div>
        )}

        {sales.whatsappNumber && (
          <div className={styles.salesDetails}>
            <IoLogoWhatsapp />{" "}
            <span className={styles.details}>{sales.whatsappNumber}</span>
          </div>
        )}
      </div>
    </div>
  );
}
