import { useState } from "react";
import { useWindowWidth } from "../../../shared/helper";

import FullScreenModal from "./fullscreen-modal/FullscreenModal";
import ShowAll from "./helper/ShowAll";
import { getCustomerById } from "../../services/profile-page-api";
import Batches from "./batches/Batches";
import { BatchCard } from "../../../beta-home/components/layouts/Batches/Batches";
import useDataFetcher from "../../../beta-home/services/helper/useDataFetcher";
import { CircularProgress } from "@mui/material";

export default function BatchesWrapperCard() {
  const { isMobile } = useWindowWidth();
  const [showModal, setShowModal] = useState(false);

  const handleShowAll = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const { data: batchData, loading } = useDataFetcher({
    getFunction: getCustomerById,
  });

  const batchesList = batchData?.customer?.batches
    ? batchData.customer.batches
    : [];

  // const recentBatch = batchesList.slice(-1)[0];

  return (
    <>
      {loading ? (
        <div className="loader-wrapper">
          <CircularProgress />
        </div>
      ) : (
        <div className="batches-wrapper-card">
          <div className="title-show-all">
            <div className="card-title-bold">Batches</div>
            {isMobile || batchesList.length > 3 ? (
              <ShowAll handleClick={handleShowAll} />
            ) : (
              ""
            )}
          </div>
          {batchesList.length > 0 ? (
            batchesList
              .slice(0, Math.min(batchesList.length, 3))
              .map((batchlist, index) => {
                return (
                  <li key={`batch-${index}`}>
                    <BatchCard batchData={batchlist} isMemberPage />
                  </li>
                );
              })
          ) : (
            <div className="empty-list">No batches available!</div>
          )}

          {showModal && (
            <FullScreenModal
              onClose={handleClose}
              title={"Batches"}
              child={<Batches />}
            />
          )}
        </div>
      )}
    </>
  );
}
