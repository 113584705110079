import { Button } from "../../../../shared/components/utils/utils";
import styles from "./Plans.module.css";
import { forwardRef } from "react";

const Plans = forwardRef(function Plans({ props }, ref) {
  return (
    <section ref={ref} className={styles.section}>
      <div className={styles.sectionTitle}>Find Your Ideal Plan</div>
      <div className={styles.description}>
        From basic beginnings to premium perks, our plans cover every need.
      </div>
      <div className={styles.cardsGrid}>
        {/* <PlansCard price="Free" duration="Lifetime" noBtn>
          Basic Plan
        </PlansCard> */}

        <PlansCard price="&#8377;999" duration="Month">
          Bronze Plan
        </PlansCard>

        <PlansCard price="&#8377;2999" duration="Month">
          Silver Plan
        </PlansCard>

        <PlansCard price="&#8377;4999" duration="Month">
          Gold Plan
        </PlansCard>
      </div>
    </section>
  );
});

export function PlansCard({ children, price, duration, noBtn }) {
  return (
    <div className={styles.sectionCard}>
      <div className={styles.card}>
        {" "}
        <div className={styles.title}>{children}</div>
        <div className={styles.price}>{price} /</div>
        <div className={styles.duration}>{duration}</div>
        {!noBtn && (
          <div className={styles.button}>
            <div>
              <button className={styles.bgWhiteBtn}>Subscribe</button>
            </div>
          </div>
        )}
      </div>
      <div className={styles.line}></div>
    </div>
  );
}

export default Plans;
