import styles from "./QueryInput.module.css";
import { useTheme } from "../../../ThemeProvider.jsx";
import SubmissionConfirmationPopup from "../../../../../member-dashboard/components/layouts/submission-confirmation-popup/SubmissionConfirmationPopup";

import { Snackbar, Alert } from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail, IoLogoWhatsapp } from "react-icons/io";
import useSubmitResponse from "../../../../services/helper/useSubmitResponse.jsx";

import getLandingPageData from "../../../../services/helper/get_landing_page_data";

import useFormHandler from "../../../../services/helper/useFormHandler.jsx";

import { useEffect } from "react";

export default function QueryInput({
  formId,
  close,
  modalClose,
  setModalClose,
}) {
  const landingPageData = getLandingPageData(window.pageData);

  const { salesPerson, business } = landingPageData;
  const { color, templateClassName } = useTheme();

  const submissionPopupIdentifiers = ["name", "mobileNumber", "email"];

  function handleModalClose() {
    close();
    clearResponses();
  }

  const {
    formRef,
    formData: leadEnquiryFormData,
    submissionConfirmationRef,
    submissionConfirmationData,
    setSubmissionConfirmationData,
    responses,
    setResponses,
    handleChange,
  } = useFormHandler({
    submissionPopupIdentifiers,
    formId,
  });

  const { handleSubmit, clearResponses, submissionError, setSubmissionError } =
    useSubmitResponse({
      formData: leadEnquiryFormData,
      formResponses: responses,
      formRef,
      setResponses,
      handleModalClose,
      submissionConfirmationRef,
    });

  useEffect(() => {
    if (modalClose) {
      clearResponses();
      setModalClose(false);
    }
  }, [modalClose]);

  return (
    <div className={`${styles.query} ${styles[templateClassName]}`} id="query">
      <div className={styles.appDetails}>
        <h1>Ask your queries</h1>
        <div className={styles.description}>
          <p>
            Please use this enquiry form to share your contact details and let
            us know how we can assist you with any questions or information you
            need.
          </p>
        </div>
      </div>
      <form ref={formRef} onSubmit={handleSubmit}>
        {leadEnquiryFormData &&
          leadEnquiryFormData.questions.map((question, index) => {
            return (
              question.visibility && (
                <div key={index}>
                  {question.type === "TEXT" && question.max_value > 100 ? (
                    <textarea
                      name={question._id}
                      type={question.type.toLowerCase()}
                      placeholder={`${question.title}${
                        question.is_required ? "*" : ""
                      }`}
                      onChange={handleChange}
                      required={question.is_required}
                      maxLength={question.max_value || undefined}
                    />
                  ) : question.type === "CHOICE" ? (
                    <Select
                      defaultValue={question.choices[0]._id}
                      name={question._id}
                      className={styles.selectField}
                      onChange={handleChange}
                      type={question.type.toLowerCase()}
                      sx={{ fontFamily: "inherit" }}
                      MenuProps={{
                        container: formRef.current,
                      }}
                    >
                      {question.choices.map((choice) => (
                        <MenuItem
                          className={styles.menuItems}
                          key={choice._id}
                          name={choice._id}
                          sx={{ fontFamily: "inherit" }}
                          value={choice._id}
                        >
                          {choice.label}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <input
                      name={question._id}
                      type={
                        question.type === "NUMBER"
                          ? "tel"
                          : question.type.toLowerCase()
                      }
                      placeholder={`${question.title}${
                        question.is_required ? "*" : ""
                      }`}
                      onChange={handleChange}
                      required={question.is_required}
                      maxLength={question.max_value || undefined}
                      minLength={question.type === "NUMBER" ? "10" : undefined}
                      pattern={
                        question.type === "NUMBER" ? "[0-9]*" : undefined
                      }
                    />
                  )}
                </div>
              )
            );
          })}
        <div className={styles.button}>
          <button className={styles.submit} type="submit">
            Submit
          </button>
        </div>
      </form>{" "}
      <div className={styles.contactSales}>
        {salesPerson.mobileNumber ||
        salesPerson.email ||
        salesPerson.whatsappNumber ? (
          <div className={styles.title}>Contact Sales</div>
        ) : (
          ""
        )}
        {salesPerson.mobileNumber && (
          <div className={styles.salesDetails}>
            <FaPhoneAlt />
            <span className={styles.details}>{salesPerson.mobileNumber}</span>
          </div>
        )}

        {salesPerson.email && (
          <div className={styles.salesDetails}>
            <IoMdMail />
            <span className={styles.details}>{salesPerson.email}</span>
          </div>
        )}

        {salesPerson.whatsappNumber && (
          <div className={styles.salesDetails}>
            <IoLogoWhatsapp />
            <span className={styles.details}>{salesPerson.whatsappNumber}</span>
          </div>
        )}
      </div>
      <Snackbar
        open={submissionError}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => setSubmissionError(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Error submitting form! Please try again.
        </Alert>
      </Snackbar>
      {submissionConfirmationData && (
        <SubmissionConfirmationPopup
          templateClassName={templateClassName}
          buttonBgColor={color}
          title={"Successfully Submitted"}
          description={
            "Thank you for reaching out! We've received your enquiry and our team will get back to you shortly."
          }
          identifiers={["Name", "Mobile", "Email"]}
          details={[
            submissionConfirmationData.name,
            submissionConfirmationData.mobileNumber,
            submissionConfirmationData.email,
          ]}
          okButton
          onClose={() =>
            setSubmissionConfirmationData({
              name: "",
              mobileNumber: "",
              email: "",
            })
          }
          ref={submissionConfirmationRef}
        />
      )}
    </div>
  );
}
