import { forwardRef } from "react";
import styles from "./SubmissionConfirmationPopup.module.css";
import { IoMdCheckmark } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { createPortal } from "react-dom";

const SubmissionConfirmationPopup = forwardRef(
  (
    {
      title,
      description,
      identifiers,
      details,
      closeButton,
      okButton,
      buttonBgColor,
      templateClassName,
      onClose,
      alignLeft,
    },
    ref
  ) => {
    const detailsArray = identifiers.map((item, index) => ({
      item,
      detail: details[index] || "",
    }));

    return createPortal(
      <dialog
        className={`${`${styles.submissionConfirmationPopup} ${
          styles[templateClassName] || ""
        }`}
        ${alignLeft ? styles.alignLeft : ""}`}
        ref={ref}
      >
        {closeButton && (
          <div
            className={styles.closeButton}
            onClick={() => {
              ref.current.close();
              onClose();
            }}
          >
            <button className={styles.button}>
              <MdClose />
            </button>
          </div>
        )}
        <div className={styles.dialogContainer}>
          <div className={styles.check}>
            <IoMdCheckmark />
          </div>{" "}
          <div className={styles.title}>{title}</div>
          <div className={styles.desc}>{description}</div>
          <div className={`${styles.paymentDetails}`}>
            {alignLeft ? (
              <ul className={styles.list}>
                {detailsArray.map(
                  (item, index) =>
                    item.detail && (
                      <li key={index}>
                        <span> {item.item}: </span>
                        <span className={styles.textBold}>
                          {" "}
                          {item.detail}
                        </span>{" "}
                      </li>
                    )
                )}
              </ul>
            ) : (
              <table className={styles.table}>
                <tbody>
                  {detailsArray.map(
                    (item, index) =>
                      item.detail && (
                        <tr key={index}>
                          <td className={styles.identifier}>{item.item}:</td>
                          <td className={styles.detail}>{item.detail}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        {okButton && (
          <div className={styles.okButton}>
            <button
              className={styles.ok}
              onClick={() => {
                ref.current.close();
                onClose && onClose();
              }}
              style={{ backgroundColor: buttonBgColor || "#0c6ef4" }}
            >
              OK
            </button>
          </div>
        )}
      </dialog>,
      document.getElementById("root")
    );
  }
);

export default SubmissionConfirmationPopup;
