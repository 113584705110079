import InvoiceCard from "../invoice-card/InvoiceCard";
import "./InvoicesList.css";

export default function InvoicesList({
  formId,
  memberInfo,
  invoicesData,
  addPaymentInvoiceId,
  pendingVerificationInvoiceId,
}) {
  return (
    <div className="mobile-dashboard-invoices-list">
      <ul>
        <li>
          <InvoiceCard
            formId={formId}
            memberInfo={memberInfo}
            invoicesData={invoicesData}
            pendingVerificationInvoiceId={pendingVerificationInvoiceId}
            addPaymentInvoiceId={addPaymentInvoiceId}
          />
        </li>
      </ul>
    </div>
  );
}
