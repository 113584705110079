import styles from "./AppDetails.module.css";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { IoLogoWhatsapp } from "react-icons/io";
import { useTheme } from "../../../ThemeProvider.jsx";

import getLandingPageData from "../../../../services/helper/get_landing_page_data";

export default function AppDetails() {
  const landingPageData = getLandingPageData(window.pageData);
  const { salesPerson, business, welcomeSection } = landingPageData;

  const { templateClassName } = useTheme();

  return (
    <div className={`${styles.appDetails} ${styles[templateClassName || ""]}`}>
      <div className={styles.header}>
        <div>
          <img src={business.logo} />
        </div>
        <div>
          <h3>{business.name}</h3>
        </div>
      </div>
      <div className={styles.image}>
        <img src={welcomeSection.image} />
      </div>
      <div className={styles.contactSales}>
        {salesPerson.mobileNumber ||
        salesPerson.email ||
        salesPerson.whatsappNumber ? (
          <div className={styles.title}>Contact Sales</div>
        ) : (
          ""
        )}
        {salesPerson.mobileNumber && (
          <div className={styles.salesDetails}>
            <FaPhoneAlt />{" "}
            <span className={styles.details}>{salesPerson.mobileNumber}</span>
          </div>
        )}

        {salesPerson.email && (
          <div className={styles.salesDetails}>
            <IoMdMail />{" "}
            <span className={styles.details}>{salesPerson.email}</span>
          </div>
        )}

        {salesPerson.whatsappNumber && (
          <div className={styles.salesDetails}>
            <IoLogoWhatsapp />{" "}
            <span className={styles.details}>{salesPerson.whatsappNumber}</span>
          </div>
        )}
      </div>
    </div>
  );
}
