import styles from "./ContactUsModal.module.css";
import { forwardRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import Snackbar from "@mui/material/Snackbar";

import { formatAddress } from "../../../../shared/helper";

const ContactUsModal = forwardRef(function ContacUsModal(
  { businessDetails },
  ref
) {
  const [showSnackbar, setShowSnackbar] = useState(false);

  function copyToClipboard(text) {
    navigator.clipboard.writeText(text);

    setShowSnackbar(true);
  }

  return (
    <dialog className={styles.contactUsModal} ref={ref}>
      <div className={styles.close}>
        <button
          className={styles.closeButton}
          onClick={() => ref.current.close()}
        >
          <IoMdClose />
        </button>
      </div>
      <div className={styles.title}>Contact Us</div>
      <div className={styles.desc}>Please use below details to contact us</div>
      {businessDetails?.mobileNumber && (
        <div className={styles.contactDetails}>
          <div className={styles.label}>Contact Number</div>
          <div className={styles.businessDetail}>
            <div className={styles.mobileNumber}>
              {businessDetails?.mobileNumber}
            </div>
            <button
              className={styles.textBlue}
              onClick={() => copyToClipboard(businessDetails?.mobileNumber)}
            >
              Copy
            </button>
          </div>
        </div>
      )}
      {businessDetails?.email && (
        <div className={styles.contactDetails}>
          <div className={styles.label}>Mail Us</div>
          <div className={styles.businessDetail}>
            <div className={styles.email}> {businessDetails?.email}</div>
            <button
              className={styles.textBlue}
              onClick={() => copyToClipboard(businessDetails?.email)}
            >
              Copy
            </button>
          </div>
        </div>
      )}
      {businessDetails?.address && (
        <div className={`${styles.contactDetails} ${styles.addressDetail}`}>
          <div className={styles.address}>Address</div>
          <div className={styles.addressDetails}>
            <span>{formatAddress(businessDetails?.address)}</span>
          </div>
        </div>
      )}

      <Snackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        message="Copied to clipboard"
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            justifyContent: "center",
            textAlign: "center",
          },
        }}
      />
    </dialog>
  );
});

export default ContactUsModal;
