import { useRef, useEffect } from "react";
import { MdCheck } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
} from "@mui/material";

import SubmissionConfirmationPopup from "../../../../../../member-dashboard/components/layouts/submission-confirmation-popup/SubmissionConfirmationPopup";
import { getPaymentInfo } from "../../../../../services/network/get-payment-info";

import useDataFetcher from "../../../../../services/helper/useDataFetcher";
import useSubmitResponse from "../../../../../services/helper/useSubmitResponse";
import useFormHandler from "../../../../../services/helper/useFormHandler";

import styles from "./NewMemberForm.module.css";
import { useTheme } from "../../../../ThemeProvider";
import { Payments } from "../../../../../../member-dashboard/components/layouts/payment-modal/PaymentsModal";

export default function NewMemberForm({
  formId,
  closeModal,
  batchData,
  modalClose,
  setModalClose,
}) {
  const { color, templateClassName } = useTheme();

  const submissionPopupIdentifiers = [];

  const {
    formRef,
    formData: memberFormData,
    submissionConfirmationRef,
    submissionConfirmationData,
    setSubmissionConfirmationData,
    responses,
    setResponses,
    handleChange,
    fileUploadResponse,
    setFileUploadResponse,
    fileMissing,
    setFileMissing,
    handleFileUploadInputChange,
    handleRefNumberChange,
  } = useFormHandler({
    submissionPopupIdentifiers,
    formId,
  });

  const fileInputRef = useRef(null);

  const {
    data,
    loading: loadingPaymentInfo,
    ErrorSnackBar,
  } = useDataFetcher({
    getFunction: getPaymentInfo,
  });

  const paymentInfo = data?.payment_info;

  function handleUploadClick() {
    fileInputRef.current.click();
  }

  function handleCancel() {
    fileInputRef.current.value = null;
    setFileUploadResponse((prevValue) => ({ ...prevValue, file_url: "" }));
  }

  function handleModalClose() {
    closeModal();
    clearResponses();
  }

  useEffect(() => {
    if (modalClose) {
      clearResponses();
      setModalClose(false);
    }
  }, [modalClose]);

  const { handleSubmit, clearResponses, submissionError, setSubmissionError } =
    useSubmitResponse({
      formData: memberFormData,
      formResponses: responses,
      formRef,
      fileInputRef,
      batchData,
      handleModalClose,
      setResponses,
      fileUploadResponse,
      setFileUploadResponse,
      setFileMissing,
      submissionConfirmationRef,
    });

  return (
    <>
      <form
        className={`${styles.batchForm} ${styles[templateClassName] || ""}`}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        {memberFormData &&
          memberFormData.questions.map((question) => {
            return (
              question.visibility && (
                <div key={question._id}>
                  {question.type === "CHOICE" ? (
                    <div className={styles.batchInput} id="form">
                      <FormControl
                        fullWidth
                        className={styles.batchInput}
                        sx={{
                          fontFamily: "inherit",
                        }}
                      >
                        <InputLabel
                          id={`label-${question._id}`}
                          sx={{
                            fontFamily: "inherit",
                          }}
                        >
                          {question.title}
                        </InputLabel>
                        <Select
                          MenuProps={{
                            container: formRef.current,
                          }}
                          name={question._id}
                          labelId={`label-${question._id}`}
                          value={
                            responses.find(
                              (res) => res.question_id === question._id
                            )?.choice || ""
                          }
                          onChange={handleChange}
                          displayEmpty
                          sx={{ fontFamily: "inherit" }}
                        >
                          {question.choices.map((choice) => (
                            <MenuItem
                              key={choice._id}
                              value={choice._id}
                              sx={{ fontFamily: "inherit" }}
                            >
                              {choice.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  ) : question.type === "FILE_UPLOAD" ? (
                    <div className={styles.payments}>
                      <Payments
                        paymentInfo={paymentInfo}
                        isNewMemberForm
                        templateClassName={templateClassName}
                      />
                      <input
                        placeholder="Enter payment reference number"
                        type="text"
                        maxLength="20"
                        className={styles.refNumber}
                        onChange={handleRefNumberChange}
                        name={question._id}
                      />
                      <div className={styles.upload}>
                        {fileUploadResponse.file_url ? (
                          <div className={styles.previewImage}>
                            <IoMdClose
                              className={styles.close}
                              onClick={handleCancel}
                            />
                            <img
                              className={styles.image}
                              src={fileUploadResponse.file_url}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <input
                          type="file"
                          ref={fileInputRef}
                          className={styles.uploadFiles}
                          onChange={handleFileUploadInputChange}
                          name={question._id}
                          accept="image/*"
                        />
                        <label
                          onClick={handleUploadClick}
                          className={styles.uploadButton}
                          htmlFor={question._id}
                        >
                          {fileUploadResponse.file_url
                            ? "Change Image"
                            : "Upload Image"}
                        </label>
                        <p
                          className={`${styles.text} ${
                            fileMissing && `${styles.errorText}`
                          }`}
                        >
                          *Upload your payment screenshot (max size: 5 MB)
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.batchInput}>
                      <TextField
                        label={question.title}
                        type={
                          question.type === "NUMBER"
                            ? "tel"
                            : question.type.toLowerCase()
                        }
                        sx={{ fontFamily: "inherit" }}
                        onChange={handleChange}
                        className={styles.input}
                        fullWidth
                        name={question._id}
                        required={question.is_required}
                        value={
                          question.title === "Batch"
                            ? batchData?.general_details?.title
                            : responses.find(
                                (res) => res.question_id === question._id
                              )?.text || ""
                        }
                        disabled={question.title === "Batch"}
                        minLength={
                          question.type === "NUMBER" ? "10" : undefined
                        }
                        pattern={
                          question.type === "NUMBER" ? "[0-9]*" : undefined
                        }
                        InputProps={{
                          style: {
                            fontFamily: "inherit",
                          },
                          maxLength: question.max_value || undefined,
                        }}
                        inputProps={{
                          maxLength: question.max_value || undefined,
                          pattern:
                            question.type === "NUMBER" ? "[0-9]*" : undefined,
                          minLength:
                            question.type === "NUMBER" ? "10" : undefined,
                        }}
                        InputLabelProps={{
                          style: {
                            fontFamily: "inherit",
                          },
                        }}
                      />
                    </div>
                  )}
                </div>
              )
            );
          })}

        <div className={styles.button}>
          <button className={styles.submit} type="submit">
            <div className={styles.checkMark}>
              <MdCheck />
            </div>
            Submit
          </button>
        </div>
        <SubmissionConfirmationPopup
          title={"Your Batch has been Booked!"}
          description={
            "Thank you for booking your batch! Once your payment is confirmed, you'll be able to join the batch."
          }
          identifiers={["Name", "Batch", "Amount", "Payment Status"]}
          details={[
            submissionConfirmationData.name,
            batchData?.general_details?.title,
            "Rs." + batchData?.pricing?.payable_amount,
            "Pending Verification",
          ]}
          buttonBgColor={color}
          okButton
          alignLeft
          onClose={() => setSubmissionConfirmationData({ name: "" })}
          ref={submissionConfirmationRef}
        />
      </form>
      <Snackbar
        open={submissionError}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => setSubmissionError(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Error submitting form! Please try again.
        </Alert>
      </Snackbar>
      {ErrorSnackBar()}
    </>
  );
}
