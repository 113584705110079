import styles from "./BatchesModal.module.css";
import { forwardRef, useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useTheme } from "../../../../ThemeProvider";

import NewMemberForm from "../new-member-form/NewMemberForm";

const BatchesModal = forwardRef(function BatchesModal(
  { batchData, formId },
  ref
) {
  function handleCloseButtonClick() {
    ref.current.close();
  }

  const { templateClassName } = useTheme();

  const [modalClose, setModalClose] = useState(false);

  return (
    <>
      {" "}
      <dialog
        className={`${styles.batchesModal} ${styles[templateClassName || ""]}`}
        ref={ref}
      >
        <header className={styles.batchesModalHeader}>
          <div className={styles.arrow}>
            <button
              className={styles.backBtn}
              onClick={() => {
                setModalClose(true);
                handleCloseButtonClick();
              }}
            >
              <FiArrowLeft />
            </button>
          </div>
          {/* <div className={styles.batchImage}>
            <img src={batchData.image_url} />
          </div> */}
          <div className={styles.batchDetails}>
            <div className={styles.batchTitle}>
              {batchData?.general_details?.title}
            </div>
            {/* <div className={styles.batchPrice}>
              Rs.{batchData.pricing.payable_amount}
            </div> */}
          </div>
        </header>
        <NewMemberForm
          formId={formId}
          closeModal={handleCloseButtonClick}
          batchData={batchData}
          modalClose={modalClose}
          setModalClose={setModalClose}
        />
      </dialog>
    </>
  );
});

export default BatchesModal;
