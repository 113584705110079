import { forwardRef } from "react";
import styles from "./MenuModal.module.css";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { MdClose } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import EnquiryModal from "../../enquiry-form/enquiry-form-modal/EnquiryModal";
import { useTheme } from "../../../ThemeProvider.jsx";

const MenuModal = forwardRef(function MenuModal(
  { batchesPage, formId, sales },
  ref
) {
  const showEnquiryModal = useRef();

  const navigate = useNavigate();

  function handleEnquiryClick() {
    showEnquiryModal.current.showModal();
  }

  function handleEnquiryModalClose() {
    showEnquiryModal.current.close();
  }

  const handleClose = () => {
    const modalElement = ref.current;

    modalElement.classList.add(styles.closing);

    setTimeout(() => {
      modalElement.classList.remove(styles.closing);
      modalElement.close();
    }, 300);
  };

  const { color, templateStyle, templateClassName } = useTheme();

  return (
    <dialog
      className={`${styles.menuModalDialog} ${styles[templateClassName]}`}
      ref={ref}
    >
      <div className={styles.closeButton}>
        <button className={styles.close} onClick={handleClose}>
          <MdClose />
        </button>
      </div>
      <ul className={styles.menuModalList}>
        <li
          onClick={
            !batchesPage ? () => ref.current.close() : () => navigate("/")
          }
          className={styles.menuItem}
        >
          Home
        </li>
        <li
          onClick={() => {
            ref.current.close();
            navigate("/#services");
          }}
          className={styles.menuItem}
        >
          Services
        </li>
        <li
          onClick={() => {
            ref.current.close();
            navigate("/#whychooseus");
          }}
          className={styles.menuItem}
        >
          Why Choose Us
        </li>
        <li
          onClick={
            batchesPage ? () => ref.current.close() : () => navigate("/batches")
          }
          className={styles.menuItem}
        >
          Batches
        </li>
        <li
          className={styles.menuItem}
          onClick={() => {
            ref.current.close();
            navigate("/#gallery");
          }}
        >
          Gallery
        </li>
        <li
          className={styles.menuItem}
          onClick={() => {
            ref.current.close();
            navigate("/#testimonials");
          }}
        >
          Testimonial
        </li>

        <li className={styles.actionButton}>
          <button
            style={{ backgroundColor: color, marginTop: "25px" }}
            onClick={handleEnquiryClick}
          >
            Enquiry
          </button>
        </li>
        <EnquiryModal
          ref={showEnquiryModal}
          onClose={handleEnquiryModalClose}
          formId={formId}
        />
        <li className={styles.actionButton}>
          <button
            style={{ backgroundColor: color }}
            onClick={() => (window.location.href = "/member")}
          >
            Sign in
          </button>
        </li>
        {sales.mobileNumber && (
          <li className={styles.call}>
            Call at{" "}
            <span>
              <FaPhoneAlt /> {sales.mobileNumber}
            </span>
          </li>
        )}
      </ul>
    </dialog>
  );
});

export default MenuModal;
