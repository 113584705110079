import AWS from "aws-sdk";

const S3_BUCKET = "startappify";
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: "AKIA4X5AIG64BUCJQ5M6",
  secretAccessKey: "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",
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const awsRoles = {
  MEMBER: 0,
  ALPHA: 1,
};

function getUploadFileKey(role, fileName) {
  if (role === awsRoles.MEMBER) {
    return `members/images/${fileName}`;
  } else if (role === awsRoles.ALPHA) {
    return `alpha/images/${fileName}`;
  }
  return fileName;
}

export const uploadFileToAWS = (file, role, type) => {
  const fileKey = getUploadFileKey(role, setFileName(file));
  const params = {
    ACL: "public-read",
    Bucket: S3_BUCKET,
    Key: fileKey,
    Body: file,
  };

  return new Promise((resolve, reject) => {
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(Math.round((evt.loaded / evt.total) * 100));
        resolve(`https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${fileKey}`);
      })
      .send((err) => {
        if (err) console.log(err);
        else console.log("File uploaded successfully!");
      });
  });
};

function setFileName(file) {
  const millies = new Date().getTime();
  let newName = "yw_" + millies;

  switch (file.type) {
    case "image/png":
      newName = newName + ".png";
      break;
    case "image/jpg":
    case "image/jpeg":
      newName = newName + ".jpg";
      break;
    default:
      // This is a non-image File So use the extension that came with the File.
      newName = newName + file.name.substr(file.name.lastIndexOf("."));
  }
  return newName;
}
